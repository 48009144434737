import React from "react";
import "./AlarmAnalytics.css";
import { Row, Col, Container } from "react-bootstrap";
import AlarmAnalyticsFilter from "../../pages/reports/alarm/AlarmAnalytics/AlarmAnalyticsFilter/AlarmAnalyticsFilter";
import AlarmAnalyticsSectionBar from "../../pages/reports/alarm/AlarmAnalytics/AlarmAnalyticsSectionBar/AlarmAnalyticsSectionBar";
import {
  ParkedAlertConfiguration,
  NonComAlertConfiguration,
} from "../../../utils/constants";
import AlarmAnalyticsPIChart from "../../pages/reports/alarm/AlarmAnalytics/AlarmAnalyticsPIChart/AlarmAnalyticsPIChart";
import BarChartComponent from "../../pages/reports/alarm/AlarmAnalytics/Barchart/Barchart";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../../helpers/hoc/loader/Loader";
import { useEffect } from "react";
import ActionTypes from "../../../store/actionTypes";
import { getAllImmobile, getAllNonCommLogs, getAllAnalyticsAlarms, getCurrentImmobileData, getCurrentNonCommData, fetchImmobilieListTableData, getAllNonCommSessionsCount, fetchNonCommListTableData, getAlarmTableDataForType } from "../../../store/actions/reports/alarm";
const { SET_SHOW_CURRENT_IMMOBILE_DATA, SET_SHOW_CURRENT_NON_COMM_DATA, SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION , SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION, SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE } = ActionTypes.REPORTS.ALARM


const AlarmAnalytics = ({
  getAllImmobile,
  getAllAnalyticsAlarms,
  fetchImmobilieListTableData,
  getAllNonCommSessionsCount,
  fetchNonCommListTableData,
  immobileDataTable,
  nonCommDataTable,
  immobileCount,
  allAnalyticsAlarmsData,
  nonCommCount,
  alarmTableData,
  getAlarmTableDataForType
}) => {
 const showCurrentImmobileData = useSelector((s) => s?.alarm?.showCurrentImmobileData)
 const showCurrentNonCommData = useSelector((s) => s?.alarm?.showCurrentNonCommLogsData)


 useEffect(() => {
   if(!selectedFromDate || !selectedToDate){
     return
   }
     getAllImmobile({fromDate: selectedFromDate, toDate: selectedToDate, showCurrentSessionOnly: showCurrentImmobileData})
 }, [showCurrentImmobileData]) // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
  if(!selectedFromDate || !selectedToDate){
    return
  }
    getAllNonCommSessionsCount({fromDate: selectedFromDate, toDate: selectedToDate, showCurrentSessionOnly: showCurrentNonCommData})
}, [showCurrentNonCommData]) // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
  if(!selectedFromDate || !selectedToDate || !alarmTableData.alertType || alarmTableData.alertType === '' ){ 
    return
  }
  getAlarmTableDataForType({alarmType: alarmTableData.alertType, pageIndex: alarmTableData.page, pageSize: 8, fromDate: selectedFromDate, toDate: selectedToDate})
},[alarmTableData.alertType, alarmTableData.page]) // eslint-disable-line react-hooks/exhaustive-deps

 const dispatch = useDispatch()
 const [selectedFromDate, setSelectedFromDate] = useState(null)
 const [selectedToDate, setSelectedToDate] = useState(null)


 const setImmobileDataSelectLabel = (label) => {
   dispatch({type: SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION, payload: label})
 }

 const setNonCommDataSelectLabel = (label) => {
  dispatch({type: SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION, payload: label})
}


 useEffect(() => {
   if(immobileDataTable.durationInterval){
     getAllImmobileListTableData(immobileDataTable.durationInterval)
   }
 },[immobileDataTable.durationInterval])  // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
  if(nonCommDataTable.durationInterval){
    getAllINonCommListTableData(nonCommDataTable.durationInterval)
  }
},[nonCommDataTable.durationInterval])  // eslint-disable-line react-hooks/exhaustive-deps


 useEffect(() => {
     getAllImmobileListTableData(immobileDataTable.durationInterval)
 },[immobileDataTable.page])  // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
  getAllINonCommListTableData(nonCommDataTable.durationInterval)
},[nonCommDataTable.page])  // eslint-disable-line react-hooks/exhaustive-deps


 const getAllImmobileListTableData = (label) => {
   if(!selectedFromDate || !selectedToDate){
     return
   }
   fetchImmobilieListTableData({
                                 durationInterval: label,
                                 fromDate: selectedFromDate,
                                 toDate: selectedToDate,
                                 pageIndex: immobileDataTable.page,
                                 pageSize: 8,
                                 showCurrentSessionOnly: showCurrentImmobileData})
 }

 /*To get all noncommunication list table data */
  const getAllINonCommListTableData = (label) => {
    if(!selectedFromDate || !selectedToDate){
      return
    }
    fetchNonCommListTableData({durationInterval: label,
                                  fromDate: selectedFromDate,
                                  toDate: selectedToDate,
                                  pageIndex: nonCommDataTable.page,
                                  pageSize: 8,
                                  showCurrentSessionOnly: showCurrentNonCommData})
  }

 /*To get all alarms data */
 const getAllData = (fromDate, toDate) => {
   setSelectedFromDate(fromDate);
   setSelectedToDate(toDate);

   dispatch({type: SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE, payload: {fromDate: fromDate, toDate: toDate}})

   getAllImmobile({fromDate: fromDate, toDate: toDate, showCurrentSessionOnly: showCurrentImmobileData})
   getAllNonCommSessionsCount({fromDate: fromDate, toDate: toDate, showCurrentSessionOnly: showCurrentNonCommData})
   getAllAnalyticsAlarms({fromDate: fromDate, toDate: toDate})
 }
 const isLoading = useSelector((s) => s?.loader?.isLoading)


 

  return (
    <Row>
      <Col>
        <div className="col">
        {/* <div className='heading-gt'style={{font: 'normal normal 600 18px/39px Inter', letterSpacing: '0px', color: '#1F2A5D', marginTop:10, marginLeft:10,
      fontSize: 16, fontWeight: 'medium'
      }}>Analytics</div> */}
        <Container fluid className="analytics-container">
                <Row className="trip-analytics-box-shadow">
                  <Col
                    xs={12}
                    className="row trip-analytics-main-container-main"
                  >
                      <div className="al__-analyticsmain-content">
                              <div className="al__alarm-analytics-filter">
                                <AlarmAnalyticsFilter
                                  onFilterChange={getAllData}
                                ></AlarmAnalyticsFilter>
                              </div>
                              <div className="al__alarm-analytics-content">
                                <div className="al__alarm-analytics-section-bar-container">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div
                                        style={{
                                          padding: "unset",
                                          width: "48%",
                                        }}
                                      >
                                         <AlarmAnalyticsSectionBar
                                          showCurrentData={showCurrentImmobileData}
                                          config = {ParkedAlertConfiguration}
                                          onSectionClick={(label)=> {setImmobileDataSelectLabel(label)}}
                                          onTableModalClose={() => {setImmobileDataSelectLabel('')}}
                                          onShowCurrentDataToggle={()=>{
                                            dispatch({type: SET_SHOW_CURRENT_IMMOBILE_DATA, payload: !showCurrentImmobileData})
                                          }}
                                          tableData={immobileDataTable}
                                          immobileData = {immobileCount}
                                          selectedDates={{ selectedFromDate, selectedToDate }}  
                                        >
                                        </AlarmAnalyticsSectionBar>
                                      </div>
                                      <div
                                        style={{
                                          padding: "unset",
                                          width: "4%",
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          padding: "unset",
                                          width: "48%",
                                        }}
                                      >
                                        <AlarmAnalyticsSectionBar
                                          showCurrentData={showCurrentNonCommData}
                                          config = {NonComAlertConfiguration}
                                          onSectionClick={(label)=> {setNonCommDataSelectLabel(label)}}
                                          onTableModalClose={() => {setImmobileDataSelectLabel('')}}
                                          onShowCurrentDataToggle={()=>{
                                            dispatch({type: SET_SHOW_CURRENT_NON_COMM_DATA, payload: !showCurrentNonCommData})
                                          }}
                                          tableData={nonCommDataTable}
                                          nonCommData = {nonCommCount} >
                                        </AlarmAnalyticsSectionBar>
                                        {/* <AlarmAnalyticsSectionBar
                                        showCurrentData={showCurrentNonCommData} 
                                          config={NonComAlertConfiguration}
                                          nonCommData={allNonCommLogsData}
                                          onShowCurrentDataToggle={()=>{
                                            dispatch({type: SET_SHOW_CURRENT_NON_COMM_DATA, payload: !showCurrentNonCommData})
                                          }}
                                        ></AlarmAnalyticsSectionBar> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="al__alarm-analytics-section-bar-container">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <AlarmAnalyticsPIChart
                                        data={allAnalyticsAlarmsData}
                                      ></AlarmAnalyticsPIChart>
                                    </div>
                                  </div>
                                </div>

                                <div className="al__alarm-analytics-section-bar-container">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <BarChartComponent />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                  </Col>
                </Row>
              </Container>
        </div>
        {isLoading && (
          <div className="al__loader">
            <Loader isLoading={isLoading} />
          </div>
        )}
      </Col>
    </Row>
  );
};
AlarmAnalytics.propTypes = {
  allImmobileData: PropTypes.array.isRequired,
  allNonCommLogsData: PropTypes.array.isRequired,
  allAnalyticsAlarmsData: PropTypes.array.isRequired,
  immobileCount: PropTypes.number.isRequired,
  immobileDataTable: PropTypes.object.isRequired,
  nonCommCount: PropTypes.number.isRequired,
  nonCommDataTable: PropTypes.object.isRequired,
  alarmTableData: PropTypes.object.isRequired,
 
 
  // Action props
  getAllImmobile: PropTypes.func.isRequired,
  getAllNonCommLogs: PropTypes.func.isRequired,
  getAllAnalyticsAlarms: PropTypes.func.isRequired,
  getCurrentImmobileData: PropTypes.func.isRequired,
  getCurrentNonCommData: PropTypes.func.isRequired,
  fetchImmobilieListTableData: PropTypes.func.isRequired,
  getAllNonCommSessionsCount: PropTypes.func.isRequired,
  fetchNonCommListTableData: PropTypes.func.isRequired,
  getAlarmTableDataForType: PropTypes.func.isRequired
 }
 
 
 const mapStateToProps = (state) => ({
  allImmobileData: state.alarm.allImmobileData,
  allNonCommLogsData: state.alarm.allNonCommLogsData,
  allAnalyticsAlarmsData: state.alarm.allAnalyticsAlarmsData,
  immobileCount: state.alarm.immobileCount,
  immobileDataTable: state.alarm.immobileDataTable,
  nonCommCount: state.alarm.nonCommCount,
  nonCommDataTable: state.alarm.nonCommDataTable,
  alarmTableData: state.alarm.alarmTableData
 })
 export default connect(mapStateToProps, {
  getAllImmobile,
  getAllNonCommLogs,
  getAllAnalyticsAlarms,
  getCurrentImmobileData,
  getCurrentNonCommData,
  fetchImmobilieListTableData,
  getAllNonCommSessionsCount,
  fetchNonCommListTableData,
  getAlarmTableDataForType
 })(AlarmAnalytics)