import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import GPListTable from './gp-group-table/GPGroupTable';
import GPCreateGroupModal from '../gp-create-group/gp-create-group-modal/GPCreateGroupModal';
import GPDeleteConfirmationModal from './gp-delete-confirmation-modal/GPDeleteConfirmationModal';

import { customToast } from '../../../../utils/toasts';
import { GetGroupsDelete, PutGroupsRename } from '../../../../store/actions/groups';
import Pagination from '../../../helpers/hoc/paginator/Pagination';
import NoCreateGroup from '../../../helpers/hoc/noCreateGroup';

import "./GPList.css"

const GPList = (props) => {
    
    const { groupsListData, fetchGroupsList, onVehicleCountClick, onCreateGroupClick, onAddSubgroupClick, onEditGroupClick,isShowViewModal,toggleViewOnboardingVehiclesModal ,toggleViewOnboardingVehiclesModalhandleclose,assigngroupName,groupAssignList} = props;

    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = React.useState('');
    const [expandedGroups, setExpandedGroups] = React.useState([]);
    const [expandedMenuId, setExpandedMenuId] = React.useState('');

    const [renameModalOpen, setRenameModalOpen] = React.useState(false);
    const [renameFormData, setRenameFormData] = React.useState({
        groupId: null,
        subgroupId: null,
        groupName: '',
        isSubgroup: null,
    });
    const [renameFormError, setRenameFormError] = React.useState({
        groupName: ''
    });

    const [deleteGroupId, setDeleteGroupId] = React.useState({});
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);

    const groupsList = groupsListData.filter((group) => {
        const searchTerm = `${group?.name}`.toLowerCase();
        return searchTerm.includes(searchQuery.toLowerCase());
    })
   

      const ITEMS_PER_PAGE = 8;
      const [currentPage, setCurrentPage] = useState(1);
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      
      const paginatedGroupsList = groupsList.slice(startIndex, endIndex).map((item, index) => ({
        ...item,
        index: startIndex + index ,
      }));
      

      
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      const handleSearchChange = (value) => {
        setSearchQuery(value);
        setCurrentPage(1); 
      };
      

    const handleGroupExpansionClick = (groupIndex, subgroupLength) => {
        setExpandedMenuId('');
        if (!subgroupLength) {
            customToast({ message: 'Subgroup(s) not present', svgImageName: 'decline' ,  theme: "light" })
            return;
        }
        setExpandedGroups((currentValue) => {
            if (currentValue.includes(groupIndex)) {
                return currentValue.filter(value => value !== groupIndex);
            }
            return [...currentValue, groupIndex];
        })
    }

    const handleMenuExpansionClick = (groupId) => {
        if (groupId === expandedMenuId) {
            setExpandedMenuId('')
        } else {
            setExpandedMenuId(groupId)
        }
    }

    const handleRenameClick = ({ groupId, subgroupId, name, isSubgroup }) => {
        setExpandedMenuId('');
        setRenameFormData({ groupName: name, groupId, subgroupId, isSubgroup })
        setRenameFormError({ groupName: '' })
        setRenameModalOpen(true);
    }

    const handleRenameChange = ({ value }) => {
        if (value.trim() === '') {
            setRenameFormError({ groupName: 'Name is required' })
        } else {
            setRenameFormError({ groupName: '' })
        }
        setRenameFormData({ ...renameFormData, groupName: value })
    }

    const handleRenameSave = () => {
        if (!renameFormData.isSubgroup && groupsListData.findIndex(g => g.name === renameFormData.groupName) !== -1) {
            customToast({ message: 'Group with the same name already exist', svgImageName: 'decline' ,  theme: "light" })
            return;
        }

        if (renameFormData.isSubgroup) {
            const groupData = groupsListData.find(d => d._id === renameFormData.groupId);
            if (groupData.subGroup.findIndex(g => g.name === renameFormData.groupName) !== -1) {
                customToast({ message: 'Subgroup with the same name already exist', svgImageName: 'decline',  theme: "light" })
                return;
            }
        }
        
        dispatch(PutGroupsRename(renameFormData.isSubgroup ? renameFormData.subgroupId : renameFormData.groupId, { name: renameFormData.groupName }));

        setTimeout(() => {
            fetchGroupsList();
            handleRenameClose();
        }, 300)
    }

    const handleRenameClose = () => {
        setRenameFormData({ groupName: '', groupId: null })
        setRenameFormError({ groupName: '' })
        setRenameModalOpen(false);
    }

    const handleDeleteClick = ({ groupId, subgroupId, isSubgroup }) => {
        setExpandedMenuId('');
        setDeleteGroupId({ groupId, subgroupId, isSubgroup });
        setDeleteConfirmationOpen(true);
    }

    const handleDeleteCancel = () => {
        setDeleteGroupId({});
        setDeleteConfirmationOpen(false);
    }

    const handleDeleteGroup = () => {
        dispatch(GetGroupsDelete(deleteGroupId));

        setTimeout(() => {
            resetPagination();
            fetchGroupsList();
            handleDeleteCancel();
        }, 300)
    }

    const handleEditClick = ({ groupData, subgroupData, subgroupIndex }) => {
        setExpandedMenuId('');
        onEditGroupClick({ groupData, subgroupData, subgroupIndex });
    }

    const handleAddSubgroupClick = ({ groupData }) => {
        setExpandedMenuId('');
        onAddSubgroupClick({ groupData });
    }

    const resetPagination = () => {
        setCurrentPage(1)
    }

    return (
   
        <div className='row mb-2 overflow-group'>
            <div className='d-flex justify-content-between align-items-center flex-row' style={{paddingLeft:'10px '}}>
                <div>
                    <div className="search-bar-div search-input">
                        <FaSearch className='vob-search-icon' />
                        <input data-testid='group-search-input' value={searchQuery} onChange={(e) => handleSearchChange(e.target.value)} type="text" placeholder='Search' className="vob-search-input search-input" />
                    </div>
                </div>
                <div className="me-2">
                    <Button data-testid='group-create-button' className='create__group__add-btn' onClick={onCreateGroupClick}>
                        <img src='/images/svgicon/groupsIcon/groupPluseIcon.svg' alt='info' /> Create Group
                    </Button>
                </div>
            </div>

            <GPListTable
                groupsList={paginatedGroupsList}
                onVehicleCountClick={onVehicleCountClick}

                onGroupExpansionClick={handleGroupExpansionClick}
                expandedGroups={expandedGroups}

                onMenuExpansionClick={handleMenuExpansionClick}
                expandedMenuId={expandedMenuId}

                onRenameClick={handleRenameClick}
                onDeleteClick={handleDeleteClick}
                onEditClick={handleEditClick}
                onAddSubgroupClick={handleAddSubgroupClick}
                toggleViewOnboardingVehiclesModal={toggleViewOnboardingVehiclesModal}
                isShowViewModal={isShowViewModal}
                toggleViewOnboardingVehiclesModalhandleclose={toggleViewOnboardingVehiclesModalhandleclose}
                assigngroupName={assigngroupName}
                groupAssignList={groupAssignList}
               />
                {paginatedGroupsList.length > 0 ? (
                <Pagination
                    disabled={false}
                    totalPages={Math.ceil(groupsList?.length / ITEMS_PER_PAGE)}
                    currentPage={currentPage}
                    setPage={handlePageChange}
                    data-testid="unresolved-pagination"
                />
                ) : (
                <NoCreateGroup />
                )}


            <GPCreateGroupModal
                isOpen={renameModalOpen}
                formData={renameFormData}
                formError={renameFormError}
                onChange={handleRenameChange}
                onSave={handleRenameSave}
                onClose={handleRenameClose}
                groupData={null}
                isRename={true}
            />

            <GPDeleteConfirmationModal
                handleClose={handleDeleteCancel}
                isOpen={deleteConfirmationOpen}
                onDeleteHandler={handleDeleteGroup}
                message={`Are you sure you want to remove this ${deleteGroupId?.isSubgroup ? 'subgroup' : 'group'}?`}
            />
        </div>
    );
}

export default GPList;