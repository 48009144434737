import { useEffect } from 'react'
import './AlarmAnalyticsSectionBar.css'
import { useState } from 'react'
import AlarmAnalyticsTable  from "../AlarmAnalyticsTable/AlarmAnalyticsTable";


const AlarmAnalyticsSectionBar = (props) => {
  const [dataToDisplay, setDataToDisplay] = useState([])

  const [isOpenParked, setIsOpenParked] = useState(false);
  const [isOpenNonCommunication, setIsOpenNonCommunication] = useState(false);
  const calculateDataForConfiguration = () => {
    const calculatedData = []
    if (props.immobileData && !props.nonCommData) {
      for (let bar of props?.config?.countsByHours) {
        bar.value = props.immobileData[bar.label]
        calculatedData.push(bar)
      }
    } else if (props.nonCommData && !props.immobileData) {
      for (let bar of props?.config?.countsByHours) {
        bar.value = props.nonCommData[bar.label]
        calculatedData.push(bar)
      }
    }
    setDataToDisplay(calculatedData)
  }

  useEffect(() => {
    calculateDataForConfiguration()
    // eslint-disable-next-line
  }, [props.immobileData, props.nonCommData])
  const handleBarContainerClick = (label) => {
  props.onSectionClick(label)
    //table data popup
    if (props?.config?.name === "Parked Vehicles") {
      setIsOpenParked(!isOpenParked);
    }
    else if (props?.config?.name === 'Non Communication Vehicles')  {          
      setIsOpenNonCommunication(!isOpenNonCommunication);
    }
    // const filteredData = configWiseData.filter(x => x.label === label)
    // if (props?.config?.name === "Parked Vehicles") {
    //   setParkedDataToPass(filteredData)
    // }
    // else if (props?.config?.name === "Non Communication Vehicles") {
    //   setNonCommDataToPass(filteredData)
    // }
  }

  return (
    <>
      <div className='al_section-bar-container container-fluid'>
        
      {isOpenParked && (
          <AlarmAnalyticsTable onClose={props.onTableModalClose} selectedDates={props.selectedDates} isOpen={isOpenParked} name={"Parked Vehicles"} setParked={setIsOpenParked} parkedData={props.tableData} showCurrentData={props.showCurrentData}/>
        )}

        {isOpenNonCommunication && (
          <AlarmAnalyticsTable onClose={props.onTableModalClose} selectedDates={props.selectedDates}  isOpen={isOpenNonCommunication} name={"Non Communication Vehicles"} setNonCommunication={setIsOpenNonCommunication} nonCommData={props.tableData} showCurrentData={props.showCurrentData}/>
        )}
        <div className='row'>
            <div className='col-7 al__status-header'>{props?.config?.name}</div>
            <div className='col-1  d-flex align-items-center'>
              <div className="material-switch pe-5">
                    <input
                      id={`custom-switch-${props?.config?.key}`}
                      name={`custom-switch-${props?.config?.key}`}
                      type="checkbox"
                      checked={!props.showCurrentData}
                      onChange={() => {
                        props.onShowCurrentDataToggle()
                      }}
                    />
                    <label htmlFor={`custom-switch-${props?.config?.key}`}></label>
              </div>
            </div>
            <div className='col-4 d-flex align-items-end'>
              <label htmlFor={`custom-switch-${props?.config?.key}`}>Show Sessions</label>
            </div>
        </div>
        <div className='container-fuild-hr'>
          <div className='row row-hr'></div>
        </div>
        <div className='al__bar-row'>
          {dataToDisplay.map((item, index) => (
            <div className='col-3' key={props?.config?.name + index}
            >
              <div className='al__bar-container'>
                <div
                  className='al__upper-bar cursor-pointer'
                  onClick={() => handleBarContainerClick(item.label)}
                  style={{ background: item.backgroundColor }}
                >
                  {item.label}
                </div>
                <div
                  className='al__bar-line cursor-pointer'
                  onClick={() => handleBarContainerClick(item.label)}
                  style={{ background: item.backgroundColor }}
                >
                  <div className='al_bar-line-bottom'></div>
                </div>
                <div
                  className='al__lower-bar cursor-pointer'
                  onClick={() => handleBarContainerClick(item.label)}
                  style={{ background: item.backgroundColor }}
                >
                  {item.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AlarmAnalyticsSectionBar
